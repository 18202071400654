import { Link } from 'gatsby';
import React, { useEffect } from 'react';

//import * as headerStyles from './sec-header.module.scss';
import app from '../styles/style';

const SecHeader = () => {
    useEffect(() => {
        app();
    }, [])

    return (
        <header>
            <nav className='nav base'>
                <div>
                    <h1><Link className="logo" to='/'>Khalid El Fasely</Link></h1>
                </div>
                <ul className='nav-links'>
                    <li>
                        <Link className='nav-item' activeClassName='nav-item-active' to='/'>Home</Link>
                    </li>
                    <li>
                        <Link className='nav-item' activeClassName='nav-item-active' to='/about'>About</Link>
                    </li>
                    <li>
                        <Link className='nav-item' activeClassName='nav-item-active' to='/blog'>Blog</Link>
                    </li>
                    <li>
                        <Link className='nav-item' activeClassName='nav-item-active' to='/contact'>Contact</Link>
                    </li>
                </ul>
                <div className='burger'>
                    <div className='line1'></div>
                    <div className='line2'></div>
                    <div className='line3'></div>
                </div>
            </nav>
        </header>
    );
};

export default SecHeader;
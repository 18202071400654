import React, { useEffect } from 'react';
import Footer from './footer';

import * as layoutStyles from './styles/layout.module.scss';
import SecHeader from './sec-header';

const Layout = ({ children }) => {
    useEffect(() => {
        document.querySelector('body').style.overflowY = "scroll";
    }, [])
    return (
        <div className='layout'>
            <div className={layoutStyles.container}>
                <section>
                    <div className={layoutStyles.content}>
                        <SecHeader />
                        <div className='children-layout'>
                            {children}
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </div>
    );
};

export default Layout;
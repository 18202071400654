import React from 'react';
import { AiFillGithub, AiFillGitlab, AiFillInstagram, AiFillLinkedin, AiFillTwitterSquare, AiFillYoutube } from 'react-icons/ai';

const SocialMedia = () => {
    return (
        <div className='social-media'>
            <a href={`${process.env.GATSBY_GH}`} target='_blank' rel='noreferrer'>
                <abbr title='GitHub'>
                    <AiFillGithub />
                </abbr>
            </a>
            <a href={`${process.env.GATSBY_GL}`} target='_blank' rel='noreferrer'>
                <abbr title='GitLab'>
                    <AiFillGitlab />
                </abbr>
            </a>
            <a href={`${process.env.GATSBY_IN}`} target='_blank' rel='noreferrer'>
                <abbr title='LinkedIn'>
                    <AiFillLinkedin />
                </abbr>
            </a>
            <a href={`${process.env.GATSBY_T}`} target='_blank' rel='noreferrer'>
                <abbr title='Twitter'>
                    <AiFillTwitterSquare />
                </abbr>
            </a>
            <a href={`${process.env.GATSBY_YOUTUBE}`} target='_blank' rel='noreferrer'>
                <abbr title='YouTube'>
                    <AiFillYoutube />
                </abbr>
            </a>
            <a href={`${process.env.GATSBY_STACKOVERFLOW}`} target='_blank' rel='noreferrer' class="stackoverflow">
                <abbr title='Stack Overflow'>
                    <svg viewBox="0 0 512 512"><path d="M294.8 361.2l-122 0.1 0-26 122-0.1L294.8 361.2zM377.2 213.7L356.4 93.5l-25.7 4.5 20.9 120.2L377.2 213.7zM297.8 301.8l-121.4-11.2 -2.4 25.9 121.4 11.2L297.8 301.8zM305.8 267.8l-117.8-31.7 -6.8 25.2 117.8 31.7L305.8 267.8zM321.2 238l-105-62 -13.2 22.4 105 62L321.2 238zM346.9 219.7l-68.7-100.8 -21.5 14.7 68.7 100.8L346.9 219.7zM315.5 275.5v106.5H155.6V275.5h-20.8v126.9h201.5V275.5H315.5z"/></svg>
                </abbr>
            </a>
            {/*<a href={`${process.env.GATSBY_FB}`} target='_blank' rel='noreferrer'>
                <abbr title='Facebook'>
                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-square" className="svg-inline--fa fa-facebook-square fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path></svg>
                </abbr>
    </a>*/}
            <a href={`${process.env.GATSBY_INSTA}`} target='_blank' rel='noreferrer'>
                <abbr title='Instagram'>
                    <AiFillInstagram />
                </abbr>
            </a>
        </div>
    );
};

export default SocialMedia;
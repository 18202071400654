import React from "react";
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from "gatsby";

const Head = ({ title, description, keywords }) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    keywords
                }
            }
        }
    `)

    return (
        <Helmet title={title ? `${title} | ${data.site.siteMetadata.title}` : data.site.siteMetadata.title}>
            <html lang="en" />
            <meta name="description" content={description ? description : data.site.siteMetadata.description} />
            <meta name="keywords" content={keywords ? keywords : data.site.siteMetadata.keywords} />
        </Helmet>
    );
};

export default Head;
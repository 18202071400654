const navSlide = () => {
    ///document.querySelector('.projects').style.backgroundColor = generateColor();
    const burger = document.querySelector('.burger');
    const nav = document.querySelector('.nav-links');
    const navLinks = document.querySelectorAll('.nav-links li');
    //const childrenLayout = document.querySelector('.children-layout');

    burger.addEventListener('click', () => {
        //Toggle Nav
        //nav.classList.toggle('nav-active');
        if(nav.classList.contains('nav-active')) {
            nav.classList.remove('nav-active');
            //childrenLayout.style.display = 'block';
            document.querySelector('body').style.overflowY = "scroll";
            ///document.querySelector('.arrow').style.opacity = "1";
        } else {
            nav.classList.add('nav-active');
            //setTimeout(() => {
            //    childrenLayout.style.display = 'none';
            //}, 750)
            document.querySelector('body').style.overflowY = "hidden";
            ///document.querySelector('.arrow').style.opacity = "0";
        }

        //Animate Links
        navLinks.forEach((link, index) => {
            if (link.style.animation) {
                link.style.animation = '';
            } else {
                link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.5}s`;
            };
        });

        //Burger Animation
        burger.classList.toggle('open');
    });

    
};

const skillsAnimate = () => {

    /// New commented

    ///const sectionTools = document.querySelector('.tools-container');
    //const sectionProjects = document.querySelector('.projects');
    ///const bars = document.querySelectorAll('.tools__item-bar');
    ///const barsSpan = document.querySelectorAll('.tools__item-bar span');
    const button = document.querySelector('.up-button');
    ///const contactButton = document.querySelector('.home__contact-me-link');
    ///const barsSpanBefore = document.querySelectorAll('.tools__item-bar span::before');
    ///const barsSpanAfter = document.querySelectorAll('.tools__item-bar span::after');
    window.onscroll = function () {
    ///    if(window.scrollY >= sectionTools.offsetTop - 300) {
    ///        bars.forEach((bar) => {
    ///            bar.style.animation = 'animate .5s cubic-bezier(1, 0, 0.5, 1) forwards';
    ///        });
    ///        barsSpan.forEach((span) => {
    ///            span.style.animation = 'animate .8s .8s cubic-bezier(1, 0, 0.5, 1) forwards';
    ///        });
    ///        //barsSpanBefore.forEach((span) => {
    ///        //    span.style.animation = 'showPerc .5s 1.5s linear forwards';
    ///        //});
    ///        //barsSpanAfter.forEach((span) => {
    ///        //    span.style.animation = 'showPerc .5s 1.5s linear forwards';
    ///        //});
    ///    }

        if(window.scrollY >= 600) {
            button.style.display = 'flex';
        }

        if(window.scrollY < 600) {
            button.style.display = 'none';
        }
    };
};

const app = () => {
    navSlide();
}

export default app;
export { skillsAnimate };